import "./login-screen.scss";
import logo from '../assets/images/logo.svg';
import Input from "../components/input/Input";

import { GoMail } from "react-icons/go";
import { HiLockClosed } from "react-icons/hi";
import { useState } from "react";
import CircleButton from "../components/circle-button/CircleButton";
import axios from "axios";
import { API_URL } from "../constants/const";

interface LoginScreenProps {
  onLogin: (data: any, formData: any) => void;
}

const LoginScreen = (props: LoginScreenProps) => {

  const { onLogin } = props;

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const onChange = (field: string) => (value: string) => {

    setFormData(prev => ({ ...prev, [field]: value }))
  }

  const onSubmit = () => {
    setIsLoading(true);
    axios.post(API_URL + "/partners/login", formData)
      .then(({ data }) => {
        if (data.success) {
          onLogin(data.partner_data, formData);
          localStorage.setItem('formData', JSON.stringify(formData));
        }
        setIsLoading(false);

      })
      .catch(e => {
        setError(e.response.data.message)
        setIsLoading(false);
      })
  }

  return <div className="screen login-screen">

    <div className="form">

      <div className="head">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          <div>Affiliates Login</div>
        </h1>
      </div>

      <div className="input-block">
        <Input value={formData.email} placeholder="Email" onChange={onChange("email")} type={"text"} icon={<GoMail />} />
        <Input value={formData.password} placeholder="Password" onChange={onChange("password")} type={"password"} icon={<HiLockClosed />} />

      </div>

      {error && <div className="error">
        {error}
      </div>}

      <div className="submit">
        <CircleButton type="submit" isActive={isLoading} onClick={onSubmit} />
      </div>
    </div>
  </div>

}

export default LoginScreen;