import { HTMLInputTypeAttribute, memo, ReactNode, useState } from 'react';
import './input.scss';
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { JsxElement } from 'typescript';


interface InputProps {
  value: string;
  onChange: (value: string) => void;
  containerStyle?: string;
  inputStyle?: string;
  type: HTMLInputTypeAttribute | undefined;
  placeholder?: string;
  inputProps?: {};

  icon?: ReactNode;
}

export default memo((props: InputProps) => {
  const {
    onChange,
    value,
    placeholder = '',
    containerStyle,
    inputStyle,
    type = 'text',
    inputProps = null,
    icon = null,
  } = props;
  const onChangeAction = (e: any) => {
    onChange(e.target.value);
  };

  const [showValue, setShowValue] = useState(false);

  const inputType =
    type === 'password' ? (showValue ? 'text' : 'password') : type;

  return (
    <div className={`input-wrapper ${containerStyle}`}>
      {icon && <div className="icon">
        {icon}
      </div>}
      <input
        type={inputType}
        onChange={inputProps ? undefined : onChangeAction}
        value={value}
        className={` ${inputStyle}`}
        placeholder={placeholder}
        {...inputProps}
      />

      {type === 'password' && (
        <button
          className={`eye ${showValue ? 'active' : null}`}
          type="button"
          onClick={() => {
            setShowValue((prev) => !prev);
          }}
        >
          {showValue ? <BsEyeSlashFill /> : <BsEyeFill />}
        </button>
      )}
    </div>
  );
});
