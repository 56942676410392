/* eslint-disable array-callback-return */
import "./statistics-screen.scss";

import { TbRefresh } from "react-icons/tb";
import { BiCopy } from "react-icons/bi";
import { RiAwardFill, RiInformationFill } from "react-icons/ri";
import LogoWhite from "../assets/images/logo-white.svg";
import { ReactNode, useEffect, useState } from "react";

interface TableData {
  campaign_name: string;
  pid: string;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  paypal_email: string;
  created_at: string;
  ref_link: string;
  coupon_code: string;
  customer_discount: string;
  signups: number;
  sales: number;
  net: number;
  percentage: number;
  active: boolean;
  cid: string;

  ios_sales: number;
  desktop_sales: number;
}

interface DataResponse {
  success: boolean;
  partner_data: any;
}

interface GeneraData {
  email: string;
  first_name: string;
  last_name: string;
  paypal_email: string;
  pid: string;
}

interface StatisticsScreenProps {
  isLoading: boolean;
  userData: {
    general: GeneraData,
    campaigns: TableData[],
  };
  getData: () => void;
  onLogout: () => void;
}

const StatisticsScreen = (props: StatisticsScreenProps) => {
  const { userData, getData, isLoading, onLogout } = props;

  const onRefresh = () => {
    getData();
  }

  const [data, setData] = useState<TableData[]>([]);

  const [totalStats, setTotalStats] = useState({
    campaings: 0,
    referals: 0,
    signups: 0,
    sales: 0,
    profit: 0,
  });

  useEffect(() => {
    setTotalStats({
      campaings: userData.campaigns.length,
      referals: 0,
      signups: userData.campaigns.reduce((acc: number, _) => acc + _.signups, 0),
      sales: userData.campaigns.reduce((acc: number, _) => acc + _.sales, 0),
      profit: userData.campaigns.reduce((acc: number, _) => acc + _.net, 0),
    })
  }, [userData.campaigns]);

  console.log('isLoading', isLoading);

  const _onLogout = () => {
    localStorage.clear();
    onLogout();
  }

  return <div className="screen statistics-screen">
    <div className="header">
      <div className="logo">
        <img src={LogoWhite} alt="" />
        GS Affilates
      </div>

      <div className="refresh">

        <button onClick={onRefresh}>
          <span className={isLoading ? "active" : ""}><TbRefresh size={24} /></span> Refresh
        </button>
        <button onClick={_onLogout}>
          Logout
        </button>
      </div>
    </div>

    <div className="title">
      <h1>welcome back, <span>{userData.general.first_name ?? ''} {userData.general.last_name ?? ''}</span></h1>
      <h2>you’re welcome to view your data in real-time</h2>
    </div>

    <div className="summary">
      <div className="totals"><span>Your Totals</span></div>

      <div className="summary-list">
        <SummaryValue name="Campaigns" icon={<RiAwardFill />} value={nFormatter(totalStats.campaings, 3)} />
        <SummaryValue name="Signups" icon={<RiAwardFill />} value={nFormatter(totalStats.signups, 0)} />
        <SummaryValue name="Sales" icon={<RiAwardFill />} value={nFormatter(totalStats.sales, 0)} />
        <SummaryValue name="Profit" icon={<RiAwardFill />} value={nFormatter(totalStats.profit, 0)} />
      </div>

    </div>

    <div className="table">
      <table cellPadding={0} cellSpacing={0}>

        <thead>
          <tr>
            <th>Campaign<br />Name</th>
            <th>REF ID</th>
            <th>coupon</th>
            <th>customer<br />discount</th>
            <th>Signups</th>
            <th>Sales</th>
            <th>Commission</th>
            <th>Net<br />earnings</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {userData.campaigns.map(_ => {
            return <tr key={_.cid}>
              <td>{_.campaign_name}</td>
              <td style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "8px"
              }}>
                <div>{_.ref_link} </div>
                <div className="info">
                  <button onClick={() => {
                    copyTextToClipboard(`https://getsound.ai/${_.ref_link}`)
                  }} className="copy"><BiCopy />
                  </button>

                </div>
              </td>
              <td>{_.coupon_code}</td>
              <td>{_.customer_discount}%</td>
              <td>{formatNumber(_.signups)} </td>
              <td className="sales">
                <div style={{
                  marginTop: "-4px",
                }}>{formatNumber(_.sales)}</div>
                <div className="info">
                  <RiInformationFill />
                  <div className="tooltip">
                    <div>iOS sales: <b>{_.ios_sales}</b></div>
                    <div>Desktop sales: <b>{_.desktop_sales}</b></div>
                  </div>
                </div>
              </td>
              <td>{_.percentage}%</td>
              <td>USD ${formatNumber(_.net)}</td>
              <td><span className={`status ${_.active ? "paid" : "unpaid"}`}>{_.active ? "paid" : "unpaid"}</span></td>
            </tr>
          })}
        </tbody>
      </table>
    </div>

  </div>
}

export default StatisticsScreen


const formatNumber = (num: number) => {

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


interface SummaryValueProps {
  name: string;
  value: string | number;
  icon: ReactNode;
}

const SummaryValue = (props: SummaryValueProps) => {

  const { name, value, icon } = props;

  return <div className="summary-value">
    {icon}
    <span className="name">{name}</span>
    <span className="value">{value}</span>
  </div>
}

function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function () {
    console.log('Async: Copying to clipboard was successful!');
  }, function (err) {
    console.error('Async: Could not copy text: ', err);
  });
}