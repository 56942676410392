import axios from 'axios';
import React, { useEffect, useState } from 'react';

import './App.scss';
import { API_URL } from './constants/const';
import LoginScreen from './screens/LoginScreen';
import StatisticsScreen from './screens/StatisticsScreen';

function App() {

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [userData, setUserData] = useState();

  const onLogin = (data: any, formData: any) => {
    setUserData(data);
    setFormData(formData);
  }

  const getData = () => {
    setIsLoading(true);
    axios.post(API_URL + "/partners/login", formData)
      .then(({ data }) => {
        if (data.success) {
          setUserData(data.partner_data);
          localStorage.setItem('formData', JSON.stringify(formData));
        }
        setIsLoading(false);
      })
      .catch(e => {
        console.log(e);
        setIsLoading(false);
      })
  }

  useEffect(() => {
    if (Object.keys(formData).length === 2) {
      getData();
    }
  }, [formData]);

  useEffect(() => {
    const _formData = JSON.parse(localStorage.getItem("formData") ?? "{}");

    if (_formData && Object.keys(_formData).length === 2) {
      setFormData(_formData);
    }

  }, []);

  const onLogout = () => {
    setUserData(undefined);
  }

  return (
    <div className="App">
      {!userData && <LoginScreen onLogin={onLogin} />}
      {userData && <StatisticsScreen userData={userData} getData={getData} isLoading={isLoading} onLogout={onLogout} />}
    </div>
  );
}

export default App;
